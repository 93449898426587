// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
// require("remodal")



jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

console.log(window.location.href);


// Обработчик события, срабатывающий при покидании страницы
// Проверяем, содержит ли текущий URL строку "/calculate"
if (!window.location.href.includes("/calculate")) {
    // Запрос к серверу для обновления состояния @cart.checkout на false
    fetch('/update_checkout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ checkout: false }),
    });
}








// Или, если вы хотите выполнить запрос, когда пользователь фактически перешел с этой страницы
// window.addEventListener('unload', function(event) {
//     fetch('/update_checkout', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ checkout: false }),
//     });
// });

// Запрос к серверу для обновления состояния @cart.checkout на true
// fetch('/update_checkout', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ checkout: true }),
// });




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")


window.onload = function () {
    if (document.querySelector('#chart')) {
        var padding = { top: 0, right: 40, bottom: 0, left: 0 },
            w = 500 - padding.left - padding.right,
            h = 500 - padding.top - padding.bottom,
            r = Math.min(w, h) / 2,
            rotation = 0,
            oldrotation = 0,
            picked = 100000;

        var data = wheelSegments;

        var svg = d3.select('#chart')
            .append("svg")
            .attr("viewBox", `0 0 ${w + padding.left + padding.right} ${h + padding.top + padding.bottom}`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style("width", "100%")
            .style("height", "auto")
            .data([data]);

        var container = svg.append("g")
            .attr("class", "chartholder")
            .attr("transform", "translate(" + (w / 2 + padding.left) + "," + (h / 2 + padding.top) + ")");

        var vis = container.append("g");

        var pie = d3.layout.pie().sort(null).value(function (d) { return 1; });

        var arc = d3.svg.arc().outerRadius(r);

        var arcs = vis.selectAll("g.slice")
            .data(pie)
            .enter()
            .append("g")
            .attr("class", "slice");

        arcs.append("path")
            .attr("fill", function (d, i) { return data[i].color; })
            .attr("d", arc);

        arcs.append("text").attr("transform", function (d) {
            d.innerRadius = 0;
            d.outerRadius = r;
            d.angle = (d.startAngle + d.endAngle) / 2;
            return "rotate(" + (d.angle * 180 / Math.PI - 90) + ")translate(" + (d.outerRadius - 50) + ")";
        })
            .attr("text-anchor", "end")
            .text(function (d, i) {
                return data[i].discount_percentage;
            })
            .style("font-size", "20px");

        svg.append("defs")
            .append("clipPath")
            .attr("id", "clip-circle")
            .append("circle")
            .attr("cx", w / 2 + padding.left)
            .attr("cy", h / 2 + padding.top)
            .attr("r", r / 3);

        svg.append("circle")
            .attr("cx", w / 2 + padding.left)
            .attr("cy", h / 2 + padding.top)
            .attr("r", r / 3)
            .style("fill", "#fff");

        svg.append("image")
            .attr("xlink:href", "logo.svg")
            .attr("x", w / 2 + padding.left - r / 4)
            .attr("y", h / 2 + padding.top - r / 4)
            .attr("width", r / 2)
            .attr("height", r / 2)
            .attr("clip-path", "url(#clip-circle)");

        document.getElementById('spine-button').addEventListener('click', spin);

        function spin(d) {
            document.getElementById('spine-button').removeEventListener('click', spin);

            var ps = 360 / data.length,
                rng = Math.floor((Math.random() * 1440) + 360);

            rotation = (Math.round(rng / ps) * ps);
            picked = Math.round(data.length - (rotation % 360) / ps);
            picked = picked >= data.length ? (picked % data.length) : picked;

            rotation += 90 - Math.round(ps / 2);

            vis.transition()
                .duration(3000)
                .attrTween("transform", rotTween)
                .each("end", function () {
                    document.getElementById('spine-button').addEventListener('click', spin);

                    sendPrizeData(data[picked].discount_percentage);

                    oldrotation = rotation;
                });
        }

        function sendPrizeData(prize) {
            const spinButton = document.getElementById('spine-button');

            fetch('/save_prize', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ prize: prize })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.spins_left === 0) {
                        spinButton.style.display = 'none'
                        document.getElementById('spine-button').style.display = 'none';
                        document.getElementById('disclaimer').style.display = 'none';
                    }

                    if (data.user_presente === true) {
                        document.getElementById('take-win-block').style.display = 'flex';
                    }
                    else {
                        document.getElementById('login-signup-switch').style.display = 'flex';
                    }

                    document.getElementById('prizes').textContent = 'Виграш: ' + data.prize;
                    document.getElementById('spines-left').textContent = 'Залишилося спроб: ' + data.spins_left;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        function rotTween() {
            var i = d3.interpolate(oldrotation % 360, rotation);
            return function (t) {
                return "rotate(" + i(t) + ")";
            };
        }

        svg.append("g")
            .attr("transform", "translate(" + (w + padding.left + padding.right) + "," + ((h / 2) + padding.top) + ")")
            .append("path")
            .attr("d", "M-" + (r * .15) + ",0L0," + (r * .05) + "L0,-" + (r * .05) + "Z")
            .style({ "fill": "black" });

        function getPrize() {
            alert("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")
            const spinButton = document.getElementById('spine-button');

            fetch('/get_prize', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        document.getElementById('take-win-block').style.display = 'none';
                        document.getElementById('login-signup-switch').style.display = 'none';
                        document.getElementById('spines-left').style.display = 'none';
                        spinButton.style.display = 'none'
                        document.getElementById('spine-button').style.display = 'none';
                        document.getElementById('disclaimer').style.display = 'none';
                        document.getElementById('prizes').textContent = 'Промокод: ' + data.promocode_code;

                        // Добавление редиректа на tops_path
                        window.location.href = '/tops';
                    } else {
                        document.getElementById('prizes').textContent = 'Вибачте щось пішло не так. Спробуйте пізніше !!!!!!!!!!!!!!';
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        document.getElementById('take-win').addEventListener('click', function () {
            getPrize()
        })

        function loginAndGetPrize() {
            const spinButton = document.getElementById('spine-button');
            const phone = document.getElementById('login__phone').value;
            const password = document.getElementById('login__password').value;

            fetch('/login_and_get_prize', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ phone: phone, password: password })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        document.getElementById('take-win-block').style.display = 'none';
                        document.getElementById('login-signup-switch').style.display = 'none';
                        document.getElementById('spines-left').style.display = 'none';
                        spinButton.style.display = 'none'
                        document.getElementById('spine-button').style.display = 'none';
                        document.getElementById('disclaimer').style.display = 'none';
                        document.getElementById('prizes').textContent = 'Промокод: ' + data.promocode_code;

                        // Добавление редиректа на tops_path
                        window.location.href = '/tops';
                    } else if (data.error && data.error.length > 0) {
                        document.getElementById('login-errors').textContent = data.error;
                    } else {
                        document.getElementById('login-errors').textContent = 'Вибачте щось пішло не так. Спробуйте пізніше';
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        document.getElementById('login-button').addEventListener('click', function () {
            loginAndGetPrize()
        })

        function signupAndGetPrize() {
            const spinButton = document.getElementById('spine-button');

            const surname = document.getElementById('sign_up_surname').value;
            const name = document.getElementById('sign_up_name').value;
            const phone = document.getElementById('sign_up__phone').value;
            const email = document.getElementById('sign_up__email').value;
            const status = document.getElementById('status').value;
            const password = document.getElementById('sign_up__password').value;
            const password_confirmation = document.getElementById('sign_up__password_confirmation').value;
            const birthday = document.getElementById('sign_up__date').value;
            const master = document.getElementById('master').checked;
            const city = document.getElementById('sign_up__city').value;
            const segment = document.getElementById('segment').value;
            const holyland = document.getElementById('holyland').checked;
            const brilace = document.getElementById('brilace').checked;
            const nanorma = document.getElementById('nanorma').checked;
            const emmebi = document.getElementById('emmebi').checked;
            const documentOne = document.getElementById('sm-ip-1').files[0];
            const documentTwo = document.getElementById('sm-ip-2').files[0];
            const politics = document.getElementById('politics').checked;
            const news = document.getElementById('news').checked;

            const formData = new FormData();

            formData.append('surname', surname);
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('status', status);
            formData.append('password', password);
            formData.append('password_confirmation', password_confirmation);
            formData.append('birthday', birthday);
            formData.append('master', master);
            formData.append('city', city);
            formData.append('segment', segment);
            formData.append('holyland', holyland);
            formData.append('brilace', brilace);
            formData.append('nanorma', nanorma);
            formData.append('emmebi', emmebi);
            formData.append('politics', politics);
            formData.append('news', news);

            formData.append('documentOne', documentOne);
            formData.append('documentTwo', documentTwo);

            fetch('/sign_up_and_get_prize', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        document.getElementById('take-win-block').style.display = 'none';
                        document.getElementById('login-signup-switch').style.display = 'none';
                        document.getElementById('spines-left').style.display = 'none';
                        spinButton.style.display = 'none'
                        document.getElementById('spine-button').style.display = 'none';
                        document.getElementById('disclaimer').style.display = 'none';
                        document.getElementById('prizes').textContent = 'Промокод: ' + data.promocode_code;

                        // Добавление редиректа на tops_path
                        window.location.href = '/tops';
                    } else if (data.error && data.error.length > 0) {
                        document.getElementById('sign-up-errors').textContent = data.error;
                    } else {
                        document.getElementById('sign-up-errors').textContent = 'Вибачте щось пішло не так. Спробуйте пізніше';
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        document.getElementById('sign-up-button').addEventListener('click', function () {
            signupAndGetPrize()
        })

        $('#master').change(function () {
            $(".master").toggle("closed");
        });

        $("#login__phone").mask("+38 (099) 999-99-99");
        $("#sign_up__phone").mask("+38 (099) 999-99-99");
    }
}
